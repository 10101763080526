import React, { memo, useEffect, useState } from "react"
import Slider from "react-slick"
import Button from "@/components/core/Button/Button"
import QuantitySelector from "@/components/cart/QuantitySelector"
import useWindowResize from "@/hooks/useWindowResize"
import htmlParser from "html-react-parser"
import { getPresetUrl, getRowCols } from "@/utils/index"
import { isMobile, isTablet } from "react-device-detect"
import ToolTip from "@/components/core/ToolTip/ToolTip"
import _isEmpty from "lodash/isEmpty"
import { singleSliderSettings } from "@/components/Default/CarouselSlick/v1/SlickSettings"
import {
  addAnalyticsForToolTip,
  addAnalyticsForArrows,
  addAnalyticsForIncludeforInstallservice,
  addAnalyticsForQuantitySelectorforinstallservice,
  addAnalyticsForQuantitySelectorforRequiredproduct,
  addAnalyticsForADDtoCart,
} from "@/components/Shared/UpsellInstallServices/analytics"
import Checkbox from "@/components/core/Checkbox/Checkbox"
import { getUserPersona } from "@/utils/helper"
import DisplayDiscountPrice from "@/components/Shared/DisplayDiscountPrice"
import RequiredProductItem from "@/components/Shared/UpsellInstallServices/RequiredProductItem"
import { getConfig } from "@/constants/config"
import ParentProductCard from "@/components/Shared/UpsellInstallServices/ParentProductCard"

const UpSellInstallServicesCarousalSlick = ({
  upSellData = [],
  currency,
  authData = {},
  handleAddToCart = () => {},
  slickRef,
  addServiceToCart = () => {},
  isSearchClick = "",
}) => {
  const {
    upSellServiceData: {
      productTitleUpSell = "",
      serviceAvailableTitleUpSell = "",
      serviceAvailableDescUpsell = "",
      toolTipsUpSell = "",
      learnAboutUpSell = "",
      priceGuardUpSell = "",
      toolTipsDescUpSell = "",
      outOfStockTitle = "",
      includeTitle = "",
      recommendationTitle = "",
      selectedTitle = "",
      presetConfigsUpSellProduct: productPresetConfigs,
      swatchUrlUpSell: baseUrlImage = "",
    } = {},
  } = authData
  const [width] = useWindowResize()
  const persona = getUserPersona()

  const [tooltip, setTooltip] = useState({ buyMoreSaveMore: false })
  const [includedItems, setIncludedItems] = useState({})
  const [brandName, setBrandName] = useState("kohler")
  const [skuPrependName, setSkuPrependName] = useState("")

  const rowCols = getRowCols(width, isMobile, isTablet)
  let arrowLabel = "Next"
  const slickSettings = singleSliderSettings(
    (_, current) => {},
    0,
    false,
    500,
    label => {
      arrowLabel = label
    },
    currentIndexAfterChange => {
      const currentSlideServiceData = upSellData[eval(currentIndexAfterChange)]
      if (!isSearchClick) {
        addAnalyticsForArrows(
          arrowLabel,
          currentSlideServiceData,
          currentIndexAfterChange,
          persona,
          serviceAvailableTitleUpSell,
          recommendationTitle
        )
      }
    }
  )
  slickSettings["infinite"] = false

  useEffect(() => {
    callToConfiguration()
    makeDefaultSelection()
  }, [])

  useEffect(() => {
    if (upSellData.length) {
      makeDefaultSelection()
    }
  }, [upSellData])

  useEffect(() => {
    checkSkuKPrepend()
  }, [])

  const makeDefaultSelection = () => {
    const includedRequiredItems = {}
    upSellData.forEach(item => {
      const {
        installServiceData: {
          discountedPrice = 0,
          price = 0,
          skuId: serviceSkuId = "",
        } = {},
        cartProductData: {
          sku: parentSku = "",
          quantity = 1,
          custom = {},
        } = {},
        requiredItems = [],
      } = item
      includedRequiredItems[parentSku] = {
        subTotal:
          getSubTotal(requiredItems, parentSku) +
          (discountedPrice || price) * quantity,
        includedItems: {
          ...requiredItems.reduce(
            (obj, cur) => ({
              ...obj,
              [cur.sku_s]: {
                check: cur.availableQuantity > 0,
                quantity: 1,
                availableQuantity: cur.availableQuantity,
                productcategory: cur.Product_Category,
                productname:
                  cur.ProductBrandName_s ||
                  cur.ProductDescriptionProductShort_s,
                project_img: cur.productImages?.labelWithUrl_ss[0],
                product_decs: cur.ProductDescriptionProductShort_s,
                warehouses: cur?.warehouse?.length && cur?.warehouse,
              },
            }),
            {}
          ),
        },
        serviceCheckStatus: !!serviceSkuId,
        serviceQty: quantity,
      }
    })

    setIncludedItems(includedRequiredItems)
  }
  const callToConfiguration = async () => {
    const config = await getConfig()
    const { general: { siteName = "kohler" } = {} } = config ?? {}
    setBrandName(siteName?.toLowerCase())
  }

  const checkSkuKPrepend = async () => {
    const { apiEndpoints: { skuPrepend = "" } = {} } = (await getConfig()) ?? {}
    setSkuPrependName(skuPrepend)
  }

  const getSubTotal = (
    requiredItems = [],
    parentSku = "",
    skuId = "",
    qty = 1
  ) => {
    const addedItems = { ...includedItems }
    const availableParentSku = addedItems[parentSku]
    return requiredItems.reduce(
      (
        n,
        {
          [`priceList.${persona}.discountedPrice_d`]: discountedPrice = 0,
          [`priceList.${persona}.price_d`]: productPrice = 0,
          sku_s: sku = "",
          availableQuantity = 0,
        }
      ) => {
        let quantity = !_isEmpty(availableParentSku)
          ? availableParentSku.includedItems[sku]?.quantity
          : 1
        skuId && sku === skuId ? (quantity = qty) : quantity
        const price = availableQuantity ? discountedPrice || productPrice : 0
        return n + price * quantity
      },
      0
    )
  }

  const checkChangeHandlerForService = (
    e,
    parentSku = "",
    index = -1,
    installServiceData
  ) => {
    const { target: { checked = false } = {} } = e
    handleServiceOperations(checked, parentSku, index)
    addAnalyticsForIncludeforInstallservice(
      checked,
      includeTitle,
      recommendationTitle,
      installServiceData,
      index
    )
  }

  const handleServiceOperations = (
    checked = false,
    parentSku = "",
    index = -1,
    qty = 1,
    handleQtyChange = false
  ) => {
    const addedItems = { ...includedItems }
    const availableParentSku = addedItems[parentSku]
    if (!_isEmpty(availableParentSku) && index > -1) {
      const serviceData = upSellData[index].installServiceData ?? {}
      const { discountedPrice = 0, price = 0 } = serviceData
      let servicePrice = availableParentSku.serviceCheckStatus
        ? (discountedPrice || price) * qty
        : 0

      if (handleQtyChange) {
        if (availableParentSku.serviceCheckStatus) {
          const checkedSkus = Object.keys(
            availableParentSku.includedItems
          ).filter(key => availableParentSku.includedItems[key]?.check)
          if (upSellData[index]?.requiredItems?.length) {
            const requiredFilteredItems = upSellData[
              index
            ].requiredItems.filter(item =>
              checkedSkus.some(sku => item.sku_s === sku)
            )
            const total =
              getSubTotal(requiredFilteredItems, parentSku) + servicePrice
            availableParentSku.subTotal = total
          }
        }
        availableParentSku.serviceQty = qty
      } else {
        const operand = checked ? "+" : "-"
        availableParentSku.serviceCheckStatus = checked
        const serviceQty = availableParentSku.serviceQty
        servicePrice = (discountedPrice || price) * serviceQty
        const { subTotal = 0 } = availableParentSku
        availableParentSku.subTotal = eval(subTotal + operand + servicePrice)
      }

      setIncludedItems(addedItems)
    }
  }

  const checkChangeHandlerForReqItems = (
    checked = false,
    parentSku = "",
    skuId = "",
    index = -1
  ) => {
    const addedItems = { ...includedItems }
    const availableParentSku = addedItems[parentSku]
    if (!_isEmpty(availableParentSku)) {
      availableParentSku.includedItems[skuId].check = checked
      if (index > -1) {
        const checkedSkus = Object.keys(
          availableParentSku.includedItems
        ).filter(key => availableParentSku.includedItems[key]?.check)
        const requiredFilteredItems = upSellData[index].requiredItems.filter(
          item => checkedSkus.some(sku => item.sku_s === sku)
        )
        const serviceData = upSellData[index].installServiceData ?? {}
        const { discountedPrice = 0, price = 0 } = serviceData
        const servicePrice = availableParentSku.serviceCheckStatus
          ? (discountedPrice || price) * (availableParentSku.serviceQty || 1)
          : 0
        availableParentSku.subTotal =
          getSubTotal(requiredFilteredItems, parentSku) + servicePrice
      }
      setIncludedItems(addedItems)
    }
  }

  const handleChange = (
    qty = 1,
    parentSku = "",
    index = -1,
    action = null,
    installServiceData = {},
    item = {}
  ) => {
    if (parentSku && index > -1) {
      handleServiceOperations(true, parentSku, index, qty, true)
      addAnalyticsForQuantitySelectorforinstallservice(
        index,
        action,
        installServiceData,
        item,
        qty
      )
    }
  }

  const quantityChangeHanlder = (
    qty = 1,
    parentSku = "",
    skuId = "",
    index = -1,
    action = null,
    requiredItemsProductdata = {},
    childindex = -1,
    installServiceDataAvailable = "",
    cartProductData = {},
    setQuantity = () => {}
  ) => {
    setQuantity(qty)
    const addedItems = { ...includedItems }
    const availableParentSku = addedItems[parentSku]
    if (!_isEmpty(availableParentSku) && index > -1) {
      const checkedSkus = Object.keys(availableParentSku.includedItems).filter(
        key => availableParentSku.includedItems[key]?.check
      )
      const requiredFilteredItems = upSellData[index].requiredItems.filter(
        item => checkedSkus.some(sku => item.sku_s === sku)
      )
      availableParentSku.includedItems[skuId].quantity = qty
      const serviceData = upSellData[index].installServiceData ?? {}
      const { discountedPrice = 0, price = 0 } = serviceData
      const servicePrice = availableParentSku.serviceCheckStatus
        ? discountedPrice || price
        : 0
      const total =
        getSubTotal(requiredFilteredItems, parentSku, skuId, qty) + servicePrice
      availableParentSku.subTotal = total
      setIncludedItems(addedItems)

      // addAnalyticsForQuantitySelectorforRequiredproduct(
      //   action,
      //   requiredItemsProductdata,
      //   index,
      //   installServiceDataAvailable ? childindex + 1 : childindex,
      //   persona,
      //   qty,
      //   cartProductData,
      //   installServiceDataAvailable
      // )
    }
  }

  const handleFieldInfoMouseOver = (
    tooltipFor = "buyMoreSaveMore",
    value = false
  ) => {
    setTooltip({ ...tooltip, [tooltipFor]: value })
  }
  const addTocarthandler = (
    item,
    index,
    parentSku,
    serviceName,
    title,
    cartProductData,
    mainTitle,
    upSellDataIndex,
    installServiceData = {}
  ) => {
    const isChildren = true
    const addedItems = { ...includedItems }
    const availableParentSku = addedItems[parentSku]
    let qty = 1
    if (!_isEmpty(availableParentSku) && index > -1) {
      qty = availableParentSku.serviceQty
    }
    const isCartAction = true
    handleAddToCart(item, qty)
    addAnalyticsForToolTip(
      serviceName,
      title,
      "",
      cartProductData,
      mainTitle,
      upSellDataIndex,
      1,
      isChildren,
      item,
      qty,
      isCartAction
    )
  }
  const installserviceClickHandler = (
    e,
    serviceName,
    cartProductData,
    upSellDataIndex = "",
    item = {}
  ) => {
    const isChildren = true
    const { target: { href = "", textContent = "" } = {} } = e
    addAnalyticsForToolTip(
      serviceName,
      textContent.toLowerCase(),
      href,
      cartProductData,
      serviceAvailableTitleUpSell
        ? htmlParser(serviceAvailableTitleUpSell)
        : recommendationTitle,
      upSellDataIndex,
      1,
      isChildren,
      item
    )
  }
  const priceGuardClickHandler = (
    e,
    serviceName,
    cartProductData,
    title = "",
    upSellDataIndex = "",
    item = {}
  ) => {
    const isChildren = true
    const { target: { href = "", textContent = "" } = {} } = e
    addAnalyticsForToolTip(
      serviceName.toLowerCase(),
      textContent.toLowerCase(),
      href,
      cartProductData,
      title,
      upSellDataIndex,
      1,
      isChildren,
      item
    )
  }

  const addSelectedItem = (
    parentSku = "",
    index = -1,
    subTotal = 0,
    item = {}
  ) => {
    if (!parentSku) return
    const addedItems = { ...includedItems }
    const availableParentSku = addedItems[parentSku]
    if (!_isEmpty(availableParentSku) && index > -1) {
      const requiredItems = availableParentSku.includedItems
      const selectedItems = Object.keys(requiredItems)
        .filter(key => requiredItems[key].check)
        .map(item => ({
          sku: item,
          quantity: requiredItems[item]?.quantity,
          warehouses: requiredItems[item]?.warehouses,
        }))
      if (availableParentSku.serviceCheckStatus && index > -1) {
        const serviceData = upSellData[index].installServiceData ?? {}
        const { skuId = "" } = serviceData
        selectedItems.push({
          sku: skuId,
          quantity: availableParentSku.serviceQty ?? 1,
        })
      }
      if (selectedItems.length) {
        const cartItems = selectedItems.map(item => ({
          ...item,
          brand: brandName,
        }))
        const requiredItemsForAnalytics = upSellData[index].requiredItems
        const serviceInfo = upSellData[index].installServiceData
        const installServiceDataAnlytics = !_isEmpty(serviceInfo)
          ? {
              ["ProductDescriptionProductShort_s"]: serviceInfo?.description,
              ["Product_Category"]: serviceInfo?.productCategory,
              image: `${serviceInfo.swatchUrl}${serviceInfo.images}?$SellModalDesktop$`,
              unitPrice: serviceInfo?.discountedPrice,
              customerFacingSKU: serviceInfo?.customerFacingSKU,
              productTileName: serviceInfo?.name,
              ["CustomerFacingBrand_s"]: serviceInfo?.customerBrand,
              availableQuantity: 1,
              sku_s: serviceInfo?.skuId,
              productParentImage: `${serviceInfo.swatchUrl}${serviceInfo.images}?$SellModalDesktop$`,
            }
          : {}

        const requiredItemsArray = !_isEmpty(installServiceDataAnlytics)
          ? [installServiceDataAnlytics, ...requiredItemsForAnalytics]
          : [...requiredItemsForAnalytics]
        const productinfo = []
        const allSelectedProductInfo = requiredItemsArray

        allSelectedProductInfo.forEach((productItem, childindex) => {
          const matchedItem =
            selectedItems.find(selItem => productItem.sku_s === selItem.sku) ??
            {}
          if (!_isEmpty(matchedItem)) {
            const { quantity: totalQTY = 1 } = matchedItem
            productinfo.push({
              ...productItem,
              childindex,
              index,
              totalQTY,
            })
          }
        })
        addServiceToCart(cartItems)
        addAnalyticsForADDtoCart(
          productinfo,
          availableParentSku.serviceCheckStatus,
          serviceInfo,
          persona,
          availableParentSku.serviceQty,
          subTotal
        )
      }
    }
  }
  const getSelectedItemNum = (item = {}) => {
    const { includedItems = {}, serviceCheckStatus = false } = item
    let num = 0
    for (const key in includedItems) {
      if (includedItems.hasOwnProperty(key)) {
        if (includedItems[key]?.check) num += 1
      }
    }
    if (serviceCheckStatus) num = num + 1
    return num
  }
  return (
    <Slider key="slider-upsell" ref={slickRef} {...slickSettings}>
      {upSellData.map((item, upSellDataIndex) => {
        const {
          cartProductData = {},
          cartProductData: {
            name = "",
            quantity = 1,
            sku = "",
            customerFacingSKU = "",
            unitPrice = 0,
            currencySign = currency,
            image = "",
          } = {},
          installServiceData: {
            quantity: installQuantity,
            name: serviceName = "",
            description = "",
            discountedPrice = 0,
            price = 0,
            images = "",
            skuId: serviceSku = "",
            presetConfigs: imgConfigs = {},
            swatchUrl: baseUrl,
          } = {},
          installServiceData = {},
          requiredItems = [],
        } = item
        const imgUrl = getPresetUrl(
          width,
          rowCols,
          baseUrl,
          images,
          imgConfigs,
          true
        )
        const imgId = image?.split("/").at(-1)

        const parsedConfigs = !_isEmpty(productPresetConfigs)
          ? JSON.parse(productPresetConfigs)
          : {}
        const productImageUrl = getPresetUrl(
          width,
          rowCols,
          baseUrlImage,
          imgId,
          parsedConfigs
        )
        const subTotal = !_isEmpty(includedItems)
          ? includedItems[sku]?.subTotal
          : 0
        const selectedIncludesNum = !_isEmpty(includedItems)
          ? getSelectedItemNum(includedItems[sku])
          : 0
        return (
          <React.Fragment key={sku}>
            <div className="upsell-modal__container">
              <ParentProductCard
                productTitleUpSell={productTitleUpSell}
                productImageUrl={productImageUrl}
                customerFacingSKU={sku}
                name={name}
                quantity={quantity}
                unitPrice={unitPrice}
                currencySign={currencySign}
                outOfStockTitle={outOfStockTitle}
                includeTitle={includeTitle}
                containerClassName=""
              />
              <div className="right-side">
                {!requiredItems.length ? (
                  <>
                    <div className="title">
                      {serviceAvailableTitleUpSell
                        ? htmlParser(serviceAvailableTitleUpSell)
                        : null}
                    </div>
                    <div className="description">
                      {serviceAvailableDescUpsell}
                    </div>
                    <div className="installation-services">
                      <div className="installation-services__left">
                        <div className="installation-services__image">
                          <img src={imgUrl} alt={serviceName} />
                        </div>
                        <div className="installation-services__links">
                          <div
                            className="installation-services__learn-more"
                            role="link"
                            onClick={e =>
                              installserviceClickHandler(
                                e,
                                serviceName,
                                cartProductData,
                                upSellDataIndex,
                                item
                              )
                            }
                          >
                            {learnAboutUpSell
                              ? htmlParser(learnAboutUpSell)
                              : null}
                          </div>
                          <div
                            className="installation-services__price-guard"
                            role="link"
                            onClick={e =>
                              priceGuardClickHandler(
                                e,
                                serviceName,
                                cartProductData,
                                serviceAvailableTitleUpSell
                                  ? htmlParser(serviceAvailableTitleUpSell)
                                  : recommendationTitle,
                                upSellDataIndex,
                                item
                              )
                            }
                          >
                            {priceGuardUpSell
                              ? htmlParser(priceGuardUpSell)
                              : null}
                          </div>
                        </div>
                      </div>
                      <div className="installation-services__right">
                        <div className="installation-services__right--mobile">
                          <div className="installation-services__title">
                            {serviceName}
                          </div>
                          <div className="installation-services__price--moblie">
                            <DisplayDiscountPrice
                              discountedPrice={discountedPrice}
                              price={price}
                              currencySign={currency}
                            />
                          </div>
                        </div>
                        <div className="installation-services__description">
                          {description}
                        </div>
                        <div className="installation-services__sku">
                          {skuPrependName.toLowerCase() ===
                          serviceSku.substring(0, 2).toLowerCase()
                            ? serviceSku
                            : `${
                                skuPrependName ? skuPrependName : ""
                              }${serviceSku}`}
                        </div>
                        <div className="installation-services__price">
                          <DisplayDiscountPrice
                            discountedPrice={discountedPrice}
                            price={price}
                            currencySign={currency}
                          />
                        </div>
                        <div className="installation-services__add-to-cart">
                          <div className="installation-services__quantity-selector">
                            <QuantitySelector
                              size="small"
                              value={installQuantity}
                              source="cart-landing"
                              onChange={(qty, action) =>
                                handleChange(
                                  qty,
                                  sku,
                                  upSellDataIndex,
                                  action,
                                  installServiceData,
                                  item
                                )
                              }
                              // loading={"true"}
                            />
                          </div>
                          <div className="add-to-cart">
                            <Button
                              role="button"
                              label={"Add To Cart"}
                              onClick={() =>
                                addTocarthandler(
                                  item,
                                  upSellDataIndex,
                                  sku,
                                  serviceName,
                                  "add to cart",
                                  cartProductData,
                                  serviceAvailableTitleUpSell
                                    ? htmlParser(serviceAvailableTitleUpSell)
                                    : null,
                                  upSellDataIndex,
                                  installServiceData
                                )
                              }
                              size="large"
                              type="primary"
                              flexible={true}
                            />
                          </div>
                        </div>
                        <div
                          className="installation-services__tool-tip"
                          onMouseOver={() => {
                            handleFieldInfoMouseOver("buyMoreSaveMore", true)
                            addAnalyticsForToolTip(
                              serviceName,
                              "buy more, save more",
                              "",
                              cartProductData,
                              serviceAvailableTitleUpSell
                                ? htmlParser(serviceAvailableTitleUpSell)
                                : recommendationTitle,
                              upSellDataIndex,
                              1,
                              true,
                              item
                            )
                          }}
                          onFocus={() => {
                            handleFieldInfoMouseOver("buyMoreSaveMore", true)
                            addAnalyticsForToolTip(
                              serviceName,
                              "buy more, save more",
                              "",
                              cartProductData,
                              serviceAvailableTitleUpSell
                                ? htmlParser(serviceAvailableTitleUpSell)
                                : recommendationTitle,
                              upSellDataIndex,
                              1,
                              true,
                              item
                            )
                          }}
                          onMouseLeave={() =>
                            handleFieldInfoMouseOver("buyMoreSaveMore", false)
                          }
                          onBlur={() =>
                            handleFieldInfoMouseOver("buyMoreSaveMore", false)
                          }
                        >
                          <div
                            className={`installation-services__tool-tip ${
                              tooltip.buyMoreSaveMore ? "--show" : "--hide"
                            }`}
                          >
                            <div className="installation-services__tool-tip-wrapper">
                              <ToolTip message={toolTipsDescUpSell}></ToolTip>
                            </div>
                          </div>
                          {toolTipsUpSell}
                        </div>
                        <div className="installation-services__links--mobile">
                          <div
                            className="installation-services__learn-more"
                            role="link"
                            onClick={e =>
                              installserviceClickHandler(
                                e,
                                serviceName,
                                cartProductData,
                                upSellDataIndex,
                                item
                              )
                            }
                          >
                            {learnAboutUpSell
                              ? htmlParser(learnAboutUpSell)
                              : null}
                          </div>
                          <div
                            className="installation-services__price-guard"
                            role="link"
                            onClick={e =>
                              priceGuardClickHandler(
                                e,
                                serviceName,
                                cartProductData,
                                serviceAvailableTitleUpSell
                                  ? htmlParser(serviceAvailableTitleUpSell)
                                  : recommendationTitle,
                                upSellDataIndex,
                                item
                              )
                            }
                          >
                            {priceGuardUpSell
                              ? htmlParser(priceGuardUpSell)
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="installation-services__mobile-landscpae">
                      <div className="mobile-landscpae__add-to-cart">
                        <div className="mobile-landscpae__quantity-selector">
                          <QuantitySelector
                            size="small"
                            value={installQuantity}
                            source="cart-landing"
                            onChange={(qty, action) =>
                              handleChange(
                                qty,
                                sku,
                                upSellDataIndex,
                                action,
                                installServiceData,
                                item
                              )
                            }
                          />
                        </div>
                        <div className="mobile-landscpae__button-to-cart">
                          <Button
                            role="button"
                            label={"Add To Cart"}
                            onClick={() =>
                              addTocarthandler(
                                item,
                                upSellDataIndex,
                                sku,
                                serviceName,
                                "add to cart",
                                cartProductData,
                                serviceAvailableTitleUpSell
                                  ? htmlParser(serviceAvailableTitleUpSell)
                                  : null,
                                upSellDataIndex
                              )
                            }
                            size="large"
                            type="primary"
                            flexible={true}
                          />
                        </div>
                      </div>
                      <div
                        className="mobile-landscpae__tool-tip"
                        onMouseOver={() => {
                          handleFieldInfoMouseOver("buyMoreSaveMore", true)
                          addAnalyticsForToolTip(
                            serviceName,
                            "buy more, save more",
                            "",
                            cartProductData,
                            serviceAvailableTitleUpSell
                              ? htmlParser(serviceAvailableTitleUpSell)
                              : recommendationTitle,
                            upSellDataIndex,
                            1,
                            true,
                            item
                          )
                        }}
                        onFocus={() => {
                          handleFieldInfoMouseOver("buyMoreSaveMore", true)
                          addAnalyticsForToolTip(
                            serviceName,
                            "buy more, save more",
                            "",
                            cartProductData,
                            serviceAvailableTitleUpSell
                              ? htmlParser(serviceAvailableTitleUpSell)
                              : recommendationTitle,
                            upSellDataIndex,
                            1,
                            true,
                            item
                          )
                        }}
                        onMouseLeave={() =>
                          handleFieldInfoMouseOver("buyMoreSaveMore", false)
                        }
                        onBlur={() =>
                          handleFieldInfoMouseOver("buyMoreSaveMore", false)
                        }
                      >
                        {toolTipsUpSell}
                      </div>
                      <div className="mobile-landscpae__links--mobile">
                        <div
                          className="mobile-landscpae__learn-more"
                          role="link"
                          onClick={e =>
                            installserviceClickHandler(
                              e,
                              serviceName,
                              cartProductData,
                              upSellDataIndex,
                              item
                            )
                          }
                        >
                          {learnAboutUpSell
                            ? htmlParser(learnAboutUpSell)
                            : null}
                        </div>
                        <div
                          className="mobile-landscpae__price-guard"
                          role="link"
                          onClick={e =>
                            priceGuardClickHandler(
                              e,
                              serviceName,
                              cartProductData,
                              serviceAvailableTitleUpSell
                                ? htmlParser(serviceAvailableTitleUpSell)
                                : recommendationTitle,
                              upSellDataIndex,
                              item
                            )
                          }
                        >
                          {priceGuardUpSell
                            ? htmlParser(priceGuardUpSell)
                            : null}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="required-parts-right-side">
                    <div className="required-item-header">
                      <div className="required-item-title">
                        {recommendationTitle}
                      </div>
                      <div className="subtotal-add-to-cart">
                        {subTotal > 0 ? (
                          <div className="required-item-price">
                            <span className="selected">{`${selectedTitle} (${selectedIncludesNum})`}</span>
                            <DisplayDiscountPrice
                              currencySign={currency}
                              price={subTotal}
                            />
                          </div>
                        ) : null}
                        <div className="add-to-cart">
                          <Button
                            role="button"
                            label={`Update Cart (${selectedIncludesNum})`}
                            onClick={() =>
                              addSelectedItem(
                                sku,
                                upSellDataIndex,
                                subTotal,
                                upSellData
                              )
                            }
                            size="large"
                            type="primary"
                            flexible={true}
                            disabled={!subTotal}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="required-item">
                      <div className="required-item-list">
                        {!_isEmpty(installServiceData) ? (
                          <div className="required-item-install-service">
                            <div className="required-item-install-service__img">
                              <img src={imgUrl} alt={serviceName} />
                            </div>
                            <div className="required-item-install-service__title">
                              {serviceName}
                            </div>
                            <div className="required-item-install-service__sku">
                              {skuPrependName.toLowerCase() ===
                              serviceSku.substring(0, 2).toLowerCase()
                                ? serviceSku
                                : `${
                                    skuPrependName ? skuPrependName : ""
                                  }${serviceSku}`}
                            </div>
                            <div className="required-item-install-service__price-quantity">
                              <div className="required-item-install-service__price">
                                <DisplayDiscountPrice
                                  discountedPrice={discountedPrice}
                                  price={price}
                                  currencySign={currencySign}
                                />
                              </div>
                              <div className="required-item-install-service__quantity">
                                <QuantitySelector
                                  doNotRequiredAnalyticsData={true}
                                  productdata={cartProductData}
                                  size="small"
                                  value={installQuantity}
                                  source="cart-landing"
                                  onChange={(qty, action) =>
                                    handleChange(
                                      qty,
                                      sku,
                                      upSellDataIndex,
                                      action,
                                      installServiceData,
                                      item
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="required-item-install-service__include">
                              <div className="include-div">
                                <div className="checkbox">
                                  {!_isEmpty(includedItems) ? (
                                    <Checkbox
                                      checked={
                                        includedItems[sku]?.serviceCheckStatus
                                      }
                                      onChange={e =>
                                        checkChangeHandlerForService(
                                          e,
                                          sku,
                                          upSellDataIndex,
                                          installServiceData
                                        )
                                      }
                                    />
                                  ) : null}
                                </div>
                                {includeTitle}
                              </div>
                              <div
                                className="price-gurd-link"
                                role="link"
                                onClick={e =>
                                  priceGuardClickHandler(
                                    e,
                                    serviceName,
                                    cartProductData,
                                    recommendationTitle
                                      ? recommendationTitle
                                      : htmlParser(serviceAvailableTitleUpSell),
                                    upSellDataIndex,
                                    item
                                  )
                                }
                              >
                                {priceGuardUpSell
                                  ? htmlParser(priceGuardUpSell)
                                  : null}
                              </div>
                            </div>
                          </div>
                        ) : null}

                        {requiredItems.map((item, index) => (
                          <React.Fragment key={item.sku_s}>
                            <RequiredProductItem
                              requiredProductItem={item}
                              persona={persona}
                              currencySign={currency}
                              parsedConfigs={parsedConfigs}
                              rowCols={rowCols}
                              baseUrl={baseUrlImage}
                              parentSku={sku}
                              includedItems={includedItems}
                              upSellDataIndex={upSellDataIndex}
                              updateRequiredItems={
                                checkChangeHandlerForReqItems
                              }
                              recommendationTitle={recommendationTitle}
                              quantityChangeHanlder={quantityChangeHanlder}
                              includeTitle={includeTitle}
                              outOfStockTitle={outOfStockTitle}
                              index={index}
                              installServiceDataAvailable={
                                !_isEmpty(installServiceData)
                              }
                              cartProductData={cartProductData}
                            />
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {requiredItems.length ? (
              <div className="subtotal-add-to-cart--mobile">
                {subTotal > 0 && subTotal != "0.00" ? (
                  <div className="number-total-mobile">
                    <div className="selected-mobile">
                      {`${selectedTitle} (${selectedIncludesNum})`}
                    </div>
                    <div className="total-price">
                      <DisplayDiscountPrice
                        currencySign={currencySign}
                        price={subTotal}
                      />
                    </div>
                  </div>
                ) : null}
                <div className="add-to-cart-">
                  <Button
                    role="button"
                    label={"Update Cart"}
                    onClick={() => addSelectedItem(sku, upSellDataIndex)}
                    size="large"
                    type="primary"
                    flexible={true}
                    disabled={!subTotal}
                  />
                </div>
              </div>
            ) : null}
          </React.Fragment>
        )
      })}
    </Slider>
  )
}

export default memo(UpSellInstallServicesCarousalSlick)
