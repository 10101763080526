import { apim } from "@/constants/api"
import axios from "axios"
import {
  createCartEndPoint,
  getCartEndPoint,
  shippingCostEndPoint,
  discountEndPoint,
  taxEndPoint,
  shareCartEndPoint,
  mergeCartEndPoint,
  cartItemsEndPoint,
  deleteCartEndPoint,
  shipmentFromCartEndPoint,
  addressSuggestionEndPoint,
  addAddressEndPoint,
  orderSubmitEndPoint,
  paymentEndPoint,
  payPalPaymentEndPoint,
  recentlyViewedEndPoint,
  checkSplitOrderEndPoint,
  panConsentEndPoint,
  addressEndPoint,
  sendTriggerURL,
  apiBaseUrl,
} from "@/constants/index"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { HYDRATE } from "next-redux-wrapper"
import { getQuantityCount, getCartIdParams } from "@/utils/cart"
import { store } from "@/store"
import { setAnonymousId } from "@/store/features/authSlice"
import { ABANDONED_CARTS_URL } from "@/constants/routes"

const initialState = {
  cart: {
    id: "",
  },
  quantity: 0,
  shipping: {},
  shipment: {},
  cartCount: 0,
  // shpping Items to checkout when product stock available
  shippingItemstoCheckout: [],
  // no items to ship from checkout when product is out of stock
  noShippingItemsInCheckout: [],
  cartUrl: "",
  showShippingDetailsForReview: {
    Parcel: false,
    LTL: false,
    Services: false,
  },
  paymentId: "",
  nameOnCard: "",
  status: "",
  isCartMergeLoading: false,
}

export const getCart = createAsyncThunk("getCart", async (payload = {}) => {
  const { isNotFromCart = "", klId = "", email = "" } = payload
  const { cartId = "" } = getCartIdParams()
  if (email !== "") {
    const url = `/cart/filter` // it's for loading customer cart data using Email
    const { data = {} } = await apim.post(url, payload)
    return data?.results.length
      ? data.results?.filter(c => c.cartState === "Active")?.[0]
      : {}
  }
  const url = klId
    ? `/cart/${klId}` // it's for loading customer cart data using KL id
    : `${getCartEndPoint}${
        isNotFromCart ? "&gfqprofile=refershCartProfile" : ""
      }${cartId}`
  const { data = {} } = await apim.get(url)

  const { anonymousId = "" } = data
  store.dispatch(setAnonymousId(anonymousId))
  return data
})

export const getAbandonedCarts = createAsyncThunk(
  "getAbandonedCarts",
  async () => {
    const { data = {} } = await apim.get(ABANDONED_CARTS_URL)
    return data
  }
)

export const createCartISCUser = createAsyncThunk(
  "createCartISCUser",
  async (body, thunkAPI) => {
    const response = await axios.post(
      `${apiBaseUrl}${createCartEndPoint}`,
      body.cartPayload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${body.guestToken}`,
        },
      }
    )
    if (response?.data?.anonymousId) {
      const { anonymousId } = response?.data
      store.dispatch(setAnonymousId(anonymousId))
    } else {
      store.dispatch(setAnonymousId(""))
    }
    let cart = response?.data
    const { id = "", type = "", version = 0 } = response?.data
    if (type === "Cart") {
      const resp = await axios.post(
        `${apiBaseUrl}/cart/items?cartId=${id}`,
        {
          version: version,
          actions: [
            {
              action: "setCustomerGroup",
              customerGroup: {
                key: "GST",
              },
            },
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${body.guestToken}`,
          },
        }
      )
      cart = resp?.data ?? {}
    }
    return cart
  }
)

export const createCart = createAsyncThunk(
  "createCart",
  async (body, thunkAPI) => {
    const state = thunkAPI.getState()
    const { isAuth, user } = state?.auth
    const isIscUser = user?.persona?.includes("IN_Kohler_ISC")
    body.currency = "INR" //Specific for INDIA PROJECT
    if (user && !isIscUser) body.customerEmail = user.sub?.toLowerCase() ?? ""
    if (user.koc)
      body.custom = {
        type: { key: "order", typeId: "type" },
        fields: { CSRAssociateId: user.koc, orderType: "ISC" },
      }
    const response = await apim.post(`${createCartEndPoint}`, body)
    if (response?.data?.anonymousId) {
      const { anonymousId } = response?.data
      store.dispatch(setAnonymousId(anonymousId))
    } else {
      store.dispatch(setAnonymousId(""))
    }
    let cart = response?.data
    const { id = "", type = "", version = 0 } = response?.data
    if (type === "Cart") {
      if (!isAuth) {
        const resp = await apim.post(`/cart/items?cartId=${id}`, {
          version: version,
          actions: [
            {
              action: "setCustomerGroup",
              customerGroup: {
                key: "GST",
              },
            },
          ],
        })
        cart = resp?.data ?? {}
      }
    }
    return cart
  }
)

export const shippingCost = createAsyncThunk("shippingCost", async id => {
  const response = await apim.get(`${shippingCostEndPoint}?cartId=${id}`)
  return response.data
})

export const discount = createAsyncThunk("discount", async body => {
  try {
    let cartID = body.cartID
    delete body.cartID
    const response = await apim.post(
      `${discountEndPoint}&cartId=${cartID}`,
      body
    )
    return response.data
  } catch (e) {
    return e.response.data
  }
})

export const taxCalculation = createAsyncThunk(
  "tax",
  async (body, thunkAPI) => {
    const actionBody = { actions: [{ action: "calculateTax" }] }
    const state = thunkAPI.getState()
    const {
      cart: { id, version },
    } = state?.cart
    actionBody.version = version
    const response = await apim.post(
      `${cartItemsEndPoint}?cartId=${id}&expand=calculation`,
      actionBody
    )
    return response.data
  }
)

export const shipmentUpdate = createAsyncThunk(
  "shipmentUpdate",
  async (body, thunkAPI) => {
    try {
      const state = thunkAPI.getState()
      const {
        cart: { id, version },
      } = state?.cart
      body.version = version
      const response = await apim.post(
        `${addressEndPoint}?cartId=${id}&expand=calculation`,
        body
      )
      return response.data
    } catch (error) {
      const {
        response: { data: { isNonDeliverableAddress = false } = {} } = {},
      } = error
      return thunkAPI.rejectWithValue({ isNonDeliverableAddress })
    }
  }
)

export const panConsentUpdate = createAsyncThunk(
  "panConsentUpdate",
  async (body, thunkAPI) => {
    try {
      const state = thunkAPI.getState()
      const {
        cart: { id, version },
      } = state?.cart
      body.version = version
      const response = await apim.post(panConsentEndPoint(id), body)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue({ error })
    }
  }
)

export const shareCart = createAsyncThunk("sharecart", async body => {
  const response = await apim.post(`${shareCartEndPoint}`, body)
  return response.data
})

export const mergeCart = createAsyncThunk("cart/merge", async payload => {
  const response = await apim.post(mergeCartEndPoint, payload)
  if (response && response.data && response.data.type === "Cart") {
    store.dispatch(setAnonymousId(""))
  }
  return response.data
})
export const addToCart = createAsyncThunk(
  "addToCart",
  async (body, thunkAPI) => {
    const state = thunkAPI.getState()
    const { user } = state?.auth
    const {
      cart: { id, version },
    } = state?.cart
    let userType = "GST"
    if (user && user?.persona) {
      userType = user?.persona[0]?.split(":")
    }
    // if (!customerGroup) {
    //   const customerGroupData = {}
    //   customerGroupData["action"] = "setCustomerGroup"
    //   customerGroupData["customerGroup"] = { key: userType }
    //   if (body.actions) {
    //     body.actions.push(customerGroupData)
    //   }
    // }
    body.version = version
    const response = await apim.post(
      `${cartItemsEndPoint}?cartId=${id}&expand=calculation`,
      body
    )
    if (response?.data?.anonymousId) {
      const { anonymousId } = response?.data
      store.dispatch(setAnonymousId(anonymousId))
    } else {
      store.dispatch(setAnonymousId(""))
    }
    return response.data
  }
)
export const removeFromCartLineItem = createAsyncThunk(
  "removeFromCartLineItem",
  async (body, thunkAPI) => {
    const state = thunkAPI.getState()
    const {
      cart: { id, version },
    } = state?.cart
    body.version = version
    const response = await apim.post(
      `${cartItemsEndPoint}?cartId=${id}&expand=calculation`,
      body
    )
    return response.data
  }
)

export const breakBundle = createAsyncThunk(
  "breakBundle",
  async (lineItemId, thunkAPI) => {
    const state = thunkAPI.getState()
    const {
      cart: { id, version },
    } = state?.cart

    const payload = {
      version,
      actions: [
        {
          action: "breakBundleItem",
          lineItemId,
        },
      ],
    }

    const response = await apim.post(
      `${cartItemsEndPoint}?cartId=${id}&expand=calculation`,
      payload
    )
    return response.data
  }
)

export const deleteCart = createAsyncThunk("deleteCart", async () => {
  const response = await apim.delete(`${deleteCartEndPoint}`)

  return response.data
})

export const sendPaybyLink = createAsyncThunk(
  "send/paybylink",
  async ({ emailId, phoneNumber, event }) => {
    const payload = {
      emailId,
      phoneNumber,
    }
    const url = `${sendTriggerURL}?evtName=${event}`
    try {
      const res = await apim.post(url, payload)
      return res
    } catch (error) {
      return error
    }
  }
)

export const shipmentCart = createAsyncThunk("shipmentcart", async body => {
  let response
  try {
    if (body && body?.assign && body?.payload) {
      response = await apim.post(
        `${shipmentFromCartEndPoint}${
          body.assign ? "?assignshipping=true" : ""
        }`,
        body.payload
      )
    } else {
      response = await apim.post(`${shipmentFromCartEndPoint}`)
    }
    return response.data
  } catch (error) {
    const cart = store?.getState()?.cart?.cart
    const shippingDetails = {
      "shipping-details": { results: [] },
      shippingCost: true,
    }
    response = await apim.get(`${shippingCostEndPoint}?cartId=${cart?.id}`)
    shippingDetails["shipping-details"]["results"] = response?.data?.results
    return shippingDetails
  }
})

export const addresssSuggest = createAsyncThunk(
  "addresssSuggest",
  async addr => {
    const response = await apim.get(
      `${addressSuggestionEndPoint}?query=${addr}&country=USA`
    )
    return response.data
  }
)
export const addAddress = createAsyncThunk("addAddress", async body => {
  const response = await apim.post(`${addAddressEndPoint}`, body)
  return response.data
})

export const checkSplitOrder = createAsyncThunk(
  "checkSplitOrder",
  async cartId => {
    const response = await apim.get(
      `${checkSplitOrderEndPoint}?cartId=${cartId}`
    )
    return response.data
  }
)

export const payment = createAsyncThunk("payment", async body => {
  const response = await apim.post(`${paymentEndPoint}`, body)
  return response.data
})

export const payPalPayment = createAsyncThunk("payPalPayment", async body => {
  const response = await apim.post(`${payPalPaymentEndPoint}`, body)
  return response.data
})
export const orderSubmit = createAsyncThunk("orderSubmit", async body => {
  const response = await apim.post(`${orderSubmitEndPoint}`, body)
  return response.data
})
export const recentlyViewed = createAsyncThunk(
  "recentlyViewed",
  async query => {
    const response = await apim.get(
      `${recentlyViewedEndPoint}?where=id in (${query})`
    )
    return response.data
  }
)
export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCart(state, action) {
      state.cart = action.payload
    },
    setCartCount(state, action) {
      state.cartCount = action.payload
    },
    setShipping(state, action) {
      state.shippingItemstoCheckout = action.payload
    },
    setNoshipping(state, action) {
      const skuIds = action?.payload?.map(item => item.sku)
      state.noShippingItemsInCheckout = skuIds || []
    },
    setShowShippingDetails(state, action) {
      state.showShippingDetailsForReview = action.payload
    },
    setCartUrl(state, action) {
      state.cartUrl = action.payload
    },
    setPaymetId(state, action) {
      state.paymentId = action.payload
    },
    increment(state, action) {
      state.quantity = state.quantity + 1
    },
    decrement(state, action) {
      state.quantity = state.quantity - 1
    },
    setNameOnCard(state, action) {
      state.nameOnCard = action.payload
    },
    setCardStatus(state, action) {
      state.isCartLoading = action.payload
    },
    setVersion(state, action) {
      state.cart.version = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase([HYDRATE], (state, action) => {
        return {
          ...state,
          ...action.payload.cart,
        }
      })
      .addCase(getCart.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(getCart.fulfilled, (state, action) => {
        state.cart = action.payload
        const lineItems = action.payload?.lineItems || []
        const customLineItems = action.payload?.customLineItems || []
        state.cartCount =
          getQuantityCount([...lineItems, ...customLineItems]) ?? 0
        state.status = "succeeded"
      })
      .addCase(getCart.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(getAbandonedCarts.pending, state => {
        state.status = "loading"
      })
      .addCase(getAbandonedCarts.fulfilled, (state, action) => {
        state.abandonedCarts = action.payload
        state.status = "succeeded"
      })
      .addCase(getAbandonedCarts.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(createCart.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(createCart.fulfilled, (state, action) => {
        state.cart = action.payload
        state.status = "succeeded"
      })
      .addCase(createCart.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(createCartISCUser.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(createCartISCUser.fulfilled, (state, action) => {
        state.cart = action.payload
        state.status = "succeeded"
      })
      .addCase(createCartISCUser.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(shippingCost.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(shippingCost.fulfilled, (state, action) => {
        state.shipping = action.payload
        state.status = "succeeded"
      })
      .addCase(shippingCost.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })

      .addCase(discount.pending, state => {
        state.status = "loading"
      })
      .addCase(discount.fulfilled, (state, action) => {
        state.status = "succeeded"
        // after discount this will set cart with updated data
        if (!action.payload.error) {
          state.cart = action.payload
        }
      })
      .addCase(discount.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(taxCalculation.pending, state => {
        state.status = "loading"
      })
      .addCase(taxCalculation.fulfilled, (state, action) => {
        state.status = "succeeded"
        // after tax this will set cart with updated data
        state.cart = action.payload
      })
      .addCase(taxCalculation.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(shipmentUpdate.pending, state => {
        state.status = "loading"
      })
      .addCase(shipmentUpdate.fulfilled, (state, action) => {
        state.status = "succeeded"
        // temp
        const activeCart =
          action.payload?.type === "Cart" &&
          action.payload?.lineItems.length > 0
        state.cart = activeCart ? action.payload : state.cart
      })
      .addCase(shipmentUpdate.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
        state.isNonDeliverableAddress = action.payload?.isNonDeliverableAddress
      })
      .addCase(shareCart.pending, state => {
        // state.status = "loading"
      })
      .addCase(shareCart.fulfilled, (state, action) => {
        // state.status = "succeeded"
      })
      .addCase(shareCart.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(addToCart.pending, (state, action) => {
        // state.status = "loading"
        state.status = "loading"
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.cart = action.payload
        const lineItems = action.payload?.lineItems || []
        const customLineItems = action.payload?.customLineItems || []
        state.cartCount =
          getQuantityCount([...lineItems, ...customLineItems]) ?? 0
        state.status = "succeeded"
      })
      .addCase(addToCart.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(deleteCart.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(deleteCart.fulfilled, (state, action) => {
        // state.cart = { id: action.payload.id, lineItems: [] }
        state.cartCount = 0
        state.status = "succeeded"
      })
      .addCase(deleteCart.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(shipmentCart.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(shipmentCart.fulfilled, (state, action) => {
        if (!action.payload?.shippingCost) {
          state.cart = { ...state.cart, ...action.payload }
        }
        state.status = "succeeded"
      })
      .addCase(shipmentCart.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(addresssSuggest.pending, (state, action) => {
        // state.status = "loading"
      })
      .addCase(addresssSuggest.fulfilled, (state, action) => {
        state.status = "succeeded"
      })
      .addCase(addresssSuggest.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(addAddress.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(addAddress.fulfilled, (state, action) => {
        // state.cart = action.payload
        state.status = "succeeded"
      })
      .addCase(addAddress.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(checkSplitOrder.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(checkSplitOrder.fulfilled, (state, action) => {
        state.cart = { ...state.cart, ...action.payload }
        state.status = "succeeded"
      })
      .addCase(checkSplitOrder.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(mergeCart.pending, (state, action) => {
        state.status = "loading"
        state.isCartMergeLoading = true
      })
      .addCase(mergeCart.fulfilled, (state, action) => {
        state.cart = action.payload
        const lineItems = action.payload?.lineItems || []
        const customLineItems = action.payload?.customLineItems || []
        state.cartCount =
          getQuantityCount([...lineItems, ...customLineItems]) ?? 0
        state.status = "succeeded"
        state.isCartMergeLoading = false
      })
      .addCase(mergeCart.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
        state.isCartMergeLoading = false
      })
      .addCase(payment.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(payment.fulfilled, (state, action) => {
        state.status = "succeeded"
      })
      .addCase(payment.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(payPalPayment.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(payPalPayment.fulfilled, (state, action) => {
        state.status = "succeeded"
      })
      .addCase(payPalPayment.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(orderSubmit.pending, (state, action) => {
        state.status = "loading" || ""
      })
      .addCase(orderSubmit.fulfilled, (state, action) => {
        // state.cart = action.payload
        state.status = "succeeded" || ""
      })
      .addCase(orderSubmit.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message || ""
      })
      .addCase(removeFromCartLineItem.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(removeFromCartLineItem.fulfilled, (state, action) => {
        state.cart = action.payload
        const lineItems = action.payload?.lineItems || []
        const customLineItems = action.payload?.customLineItems || []
        state.cartCount =
          getQuantityCount([...lineItems, ...customLineItems]) ?? 0
        state.status = "succeeded"
      })
      .addCase(removeFromCartLineItem.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(breakBundle.pending, state => {
        state.status = "loading"
      })
      .addCase(breakBundle.fulfilled, (state, action) => {
        state.cart = action.payload
        const lineItems = action.payload?.lineItems || []
        const customLineItems = action.payload?.customLineItems || []
        state.cartCount =
          getQuantityCount([...lineItems, ...customLineItems]) ?? 0
        state.status = "suceeded"
      })
      .addCase(breakBundle.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(recentlyViewed.pending, state => {
        state.status = "loading"
      })
      .addCase(recentlyViewed.fulfilled, (state, action) => {
        state.status = "succeeded"
      })
      .addCase(recentlyViewed.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
  },
})

export const {
  setCart,
  increment,
  decrement,
  setCartCount,
  setCartUrl,
  setShipping,
  setNoshipping,
  setShowShippingDetails,
  setPaymetId,
  setNameOnCard,
  setCardStatus,
  setPanNumber,
  setVersion,
} = cartSlice.actions
export const selectCartState = state => state.cart
export default cartSlice.reducer
