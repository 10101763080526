import { aemAxiosInstance, apim } from "@/constants/api"
import qs from "qs"
import { getConfig } from "@/constants/config"
import { getSessionIdObjWithEpoc } from "@/utils/ApiUtil"
import { getStockEndPoint } from "@/constants/index"

const getListOfParamsToFetch = persona => {
  const fl = [
    "id",
    "slug_s",
    "productName_s",
    "masterSKU_s",
    "sku_s",
    "Product_Category",
    "ProductBrandName_s",
    "productImages.url_s",
    "productImages.url_ss",
    "SKUColorSwatchFilename_s",
    "SKUColorSwatchFilename_ss",
    "ProductDescriptionProductShort_s",
    `priceStartingAt.${persona}_d`,
    `discountedPriceStartingAt.${persona}_d`,
    `priceList.${persona}.saleOffer_s`,
    "Color.SKU.Details_ss",
    "Color.SKU.Details_s",
    "ProductWebFeatures_ss",
    "ProductWebFeatures_s",
    "ProductIsExclusive_s",
    "RegionReleaseforShipment_dt",
    "title_s",
    "ProductNarrativeDescription_s",
    `priceList.${persona}.finalPrice_d`,
    "CustomerFacingBrand_s",
    "RegionBrandName_s",
    `priceList.${persona}.discountedPrice_d`,
    `priceList.${persona}.price_d`,
    "productImages.labelWithUrl_ss",
    "CustomerFacingSKU_s",
    "CrossSellRecommendationSKUList_ss",
  ]

  return fl.join(",")
}

const getProductDataFromSkuIds = async (
  persona = "GST",
  skus = [],
  brandName = "kohler"
) => {
  const fl = getListOfParamsToFetch(persona)
  const fq = skus.map(
    item => `variantList.sku_s:("${item}") OR variantList.sku_ss:("${item}")`
  )

  const config = await getConfig()
  const language = config?.internationalization?.language ?? "en"
  const brand = config?.general?.siteName ?? ""
  return apim.get(`/search/plp`, {
    params: {
      fl,
      q: "*:*",
      fq: [fq.join(" "), `language_s:("${language}")`],
      collections: brand.toLowerCase(),
      profilename: `profile_${brand.toLowerCase()}_General`,
      rows: skus?.length ?? 10,
      sessionId: getSessionIdObjWithEpoc(),
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: "repeat", encode: false })
    },
  })
}
export const getListOfRequiredSkus = async (
  persona = "GST",
  slug = "",
  sku = "",
  lwAppName = ""
) => {
  try {
    const fl = getListOfParamsToFetch(persona)
    const config = await getConfig()
    const language = config?.internationalization?.language ?? "en"
    const fq = `sku_s:("${sku}")`

    return apim.post(`search/pdp/${slug}`, {
      fl,
      q: "*:*",
      fq: `${fq} AND language_s:("${language}")`,
      collections: "kohler-india",
      profilename: `profile_kohler-india_General`,
      sessionId: getSessionIdObjWithEpoc(),
    })
  } catch (err) {
    return {}
  }
}
export const getProductInventory = (skus = []) => {
  return apim.get(`${getStockEndPoint}/?sku=${skus}`)
}

const getZipCodes = jsonFileUrl => aemAxiosInstance.get(jsonFileUrl)

export { getProductDataFromSkuIds, getZipCodes }
